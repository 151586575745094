import React from "react";

const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={130}
      height={100}
      viewBox="0 0 152.5 116.416"
    >
      <g data-name="Property Care Logo" transform="translate(-105 -560.584)">
        <g data-name="Group 18" transform="translate(16.344 71.776)">
          <path
            data-name="Path 45"
            d="M193.368 420.119v-5.278a1.946 1.946 0 012.075-2.091h10.806a1.938 1.938 0 012.058 2.058q.006 6.374 0 12.748v.608h4.952c.009-.136.026-.278.026-.42q0-9.909-.013-19.818a.983.983 0 00-.3-.66q-11-9.573-22.03-19.123c-.123-.107-.252-.206-.407-.333l-2.489 2.174c-1.711 1.5-3.426 2.989-5.127 4.5a.808.808 0 01-.76.209c-.678-.122-1.363-.206-2.157-.322.764-.674 1.451-1.284 2.142-1.888q3.41-2.982 6.822-5.962a2.191 2.191 0 013.171.007q5.136 4.45 10.267 8.9c.111.1.226.186.4.328.011-.215.025-.369.025-.523V391a1.009 1.009 0 011.153-1.159h7.021c.97 0 1.288.314 1.288 1.272q0 6.2-.007 12.4a.942.942 0 00.357.805q4.019 3.458 8.017 6.941c.1.087.2.172.3.267a.988.988 0 01.043 1.39.964.964 0 01-1.348.11c-.557-.445-1.085-.927-1.624-1.394l-2.713-2.353v18.909h8a3.688 3.688 0 01.5.011.985.985 0 01.024 1.966 3.223 3.223 0 01-.5.015h-66.082c-.749-.008-1.241-.405-1.238-1s.5-.991 1.246-.991h8.185V409.8c-.109.08-.212.144-.3.222q-1.969 1.718-3.936 3.44a.985.985 0 01-1.195.164.88.88 0 01-.5-.949 1.16 1.16 0 01.31-.649c2.307-2.044 4.63-4.071 7.059-6.2a1.491 1.491 0 00.435 1.459c.152.145.144.5.144.754q.011 9.76.006 19.52v.586h25.9v-8.028m11.006 8.067h1.907v-13.423h-10.914v13.424h9m1.6-36.354h-1.147c0 1.874-.006 3.679.012 5.483a.713.713 0 00.26.435c.842.749 1.7 1.483 2.548 2.221s1.717 1.485 2.626 2.272v-10.412z"
            transform="translate(-29.534 131.329)"
            fill="#2f4457"
          />
          <path
            data-name="Path 47"
            d="M269.831 456.572a1 1 0 01-1.374.222c-1.225-.626-2.441-1.273-3.677-1.877a.93.93 0 00-.718 0c-1.206.589-2.4 1.214-3.585 1.839a1.114 1.114 0 01-1.261.026 1.093 1.093 0 01-.366-1.209c.218-1.24.43-2.482.664-3.72a.912.912 0 00-.3-.96c-.934-.847-1.823-1.743-2.745-2.6a1.128 1.128 0 01-.464-1.262c.186-.536.639-.654 1.144-.721 1.315-.174 2.624-.386 3.939-.554a.631.631 0 00.541-.414c.613-1.232 1.246-2.454 1.859-3.686a.982.982 0 01.934-.619.993.993 0 01.931.628c.62 1.247 1.245 2.492 1.891 3.725a.735.735 0 00.465.339c1.342.216 2.688.409 4.035.589a1.138 1.138 0 011.087.725 1.107 1.107 0 01-.44 1.225c-.956.9-1.889 1.819-2.851 2.709a.724.724 0 00-.241.745c.226 1.239.463 2.477.648 3.722a3.993 3.993 0 01-.118 1.136m-8.449-6.38c.4.4.3.875.213 1.353-.15.832-.292 1.665-.453 2.587.911-.467 1.732-.858 2.523-1.3a1.381 1.381 0 011.493-.009c.8.452 1.639.851 2.54 1.313-.174-1-.317-1.909-.5-2.814a1.258 1.258 0 01.417-1.29c.6-.544 1.178-1.119 1.762-1.683a3.946 3.946 0 00.259-.312c-1-.147-1.935-.3-2.87-.414a1.246 1.246 0 01-1.052-.783c-.4-.845-.837-1.675-1.3-2.593-.4.8-.816 1.5-1.113 2.249a1.777 1.777 0 01-1.737 1.212c-.781.047-1.554.211-2.18.3.634.695 1.29 1.417 1.998 2.184z"
            transform="translate(-119.507 81.635)"
            fill="#2f4457"
          />
          <path
            data-name="Path 49"
            d="M720.008 304.1c.388.056.737.087 1.075.165a.983.983 0 01.508 1.691c-.472.49-.958.967-1.466 1.418a.839.839 0 00-.312.906c.141.63.22 1.274.345 1.908a1.016 1.016 0 01-1.52 1.127c-.629-.334-1.255-.678-1.9-.977a.83.83 0 00-.62-.009c-.646.3-1.272.641-1.9.977a1 1 0 01-1.166-.039 1.02 1.02 0 01-.369-1.109c.1-.52.13-1.06.281-1.565a1.17 1.17 0 00-.45-1.415c-.428-.336-.789-.758-1.188-1.132a1.017 1.017 0 01-.337-1.12 1.038 1.038 0 01.939-.7c.706-.1 1.414-.186 2.114-.318a.759.759 0 00.468-.335c.335-.609.631-1.24.935-1.866a1.031 1.031 0 011.945-.007c.282.582.589 1.151.849 1.743a.722.722 0 00.693.51c.346.016.689.1 1.077.151m-1.492 2.054l.229-.283a1.994 1.994 0 01-2.342-1.595 2.03 2.03 0 01-2.284 1.665 2.056 2.056 0 01.871 2.686 2 2 0 012.8.041 1.767 1.767 0 000-.436 1.677 1.677 0 01.725-2.081z"
            transform="translate(-529.178 207.707)"
            fill="#304457"
          />
          <path
            data-name="Path 50"
            d="M190.535 307a1.412 1.412 0 00-.616 1.8 12.4 12.4 0 01.264 1.415 1 1 0 01-.355 1.024.97.97 0 01-1.119.1c-.663-.339-1.313-.7-1.985-1.024a.762.762 0 00-.577 0c-.644.3-1.27.643-1.9.98a1.016 1.016 0 01-1.167-.02 1.03 1.03 0 01-.376-1.148c.131-.632.21-1.275.339-1.907a.739.739 0 00-.251-.784c-.493-.443-.95-.925-1.436-1.376a1.028 1.028 0 01-.36-1.111 1.039 1.039 0 01.968-.731c.706-.1 1.413-.187 2.113-.315a.7.7 0 00.434-.309c.312-.565.592-1.148.867-1.732.215-.455.469-.847 1.043-.854.6-.007.856.408 1.078.877.255.539.541 1.063.787 1.605a.629.629 0 00.575.441c.659.061 1.311.188 1.968.274a1.1 1.1 0 011.034.72 1.07 1.07 0 01-.409 1.189c-.308.277-.6.574-.921.887m-2.854.359l.941-1.479a1.923 1.923 0 01-2.189-1.609 2.081 2.081 0 01-2.3 1.68 1.974 1.974 0 01.862 2.608 4.934 4.934 0 011.471-.5 4.409 4.409 0 011.382.506c-.057-.4-.111-.759-.164-1.206z"
            transform="translate(-51.982 207.712)"
            fill="#304457"
          />
          <path
            data-name="Path 51"
            d="M347.675 298.485c.485.074.93.128 1.369.212a.988.988 0 01.567 1.7c-.354.4-.757.747-1.1 1.151a.889.889 0 00-.2.59c.047.544.151 1.084.246 1.622a1.009 1.009 0 01-1.492 1.09c-.556-.291-1.111-.8-1.669-.808-.537 0-1.076.521-1.617.809a1.008 1.008 0 01-1.488-1.092 8.73 8.73 0 01.165-.98 1.359 1.359 0 00-.522-1.678 4.36 4.36 0 01-.729-.746.985.985 0 01.561-1.652 4.438 4.438 0 01.981-.155 1.221 1.221 0 001.323-.986 6.561 6.561 0 01.438-.946 1.005 1.005 0 011.841.02c.211.45.452.888.631 1.35a.687.687 0 00.691.5m-2.516 1.408l-1.046.589a1.4 1.4 0 01.467 1.674 1.265 1.265 0 011.727.02 1.482 1.482 0 01.568-1.826 1.319 1.319 0 01-1.435-.962 7.448 7.448 0 01-.277.504z"
            transform="translate(-196.035 212.198)"
            fill="#304557"
          />
          <path
            data-name="Path 52"
            d="M552.522 268.282l1.469.209a.843.843 0 01.8.7 1.543 1.543 0 01-.253.993 14.5 14.5 0 01-1.088 1.085.8.8 0 00-.185.549 13.669 13.669 0 00.232 1.421 1.006 1.006 0 01-1.47 1.112c-.414-.208-.839-.4-1.233-.643a.742.742 0 00-.865.011c-.382.23-.793.413-1.189.619a.979.979 0 01-1.121-.06.894.894 0 01-.36-1.055 2.247 2.247 0 00-.977-2.945.911.911 0 01-.344-1.108.975.975 0 01.885-.689 9.514 9.514 0 011.081-.154 1.01 1.01 0 001.067-.753 8.706 8.706 0 01.462-.934 1 1 0 01.932-.629.982.982 0 01.937.617 12.1 12.1 0 00.64 1.232 1.8 1.8 0 00.577.422m-1.146 1.849l-1.012-.66-1.039.782.379 1.344a1.091 1.091 0 011.286-.045c.19-.44.367-.838.532-1.242.018-.037-.043-.11-.143-.179z"
            transform="translate(-380.564 239.223)"
            fill="#304457"
          />
          <path
            data-name="Path 53"
            d="M461.848 536.956c-.68-.243-.862-.489-.864-1.137v-3.731a.987.987 0 011.108-1.1q2.885-.006 5.77 0a.981.981 0 011.087 1.072q.01 1.915 0 3.83a.982.982 0 01-1.089 1.07h-6.013m5.112-2.45V533h-3.953v1.966c1.24 0 2.449-.009 3.658.006.31.007.309-.158.295-.466z"
            transform="translate(-304.121 .643)"
            fill="#2f4457"
          />
          <path
            data-name="Path 54"
            d="M427.841 198.965c-.688-.287-.942-.619-.75-1.278a2.132 2.132 0 00-.822-2.617.916.916 0 01-.315-1.115.9.9 0 01.9-.66 1.877 1.877 0 002.051-1.553.945.945 0 01.957-.731.961.961 0 011 .744 1.859 1.859 0 002.008 1.533 1 1 0 01.613 1.792 2.072 2.072 0 00-.806 2.568.953.953 0 01-.374 1.145.943.943 0 01-1.2-.026 1.739 1.739 0 00-2.428-.011 2.076 2.076 0 01-.831.208m2.556-3.364l.288-.553-.8-.544-.766.516.339 1h.912a4.15 4.15 0 01.027-.419z"
            transform="translate(-272.521 306.752)"
            fill="#304457"
          />
          <path
            data-name="Path 55"
            d="M671.163 193.446c.549-.125 1.072-.208 1.582-.342a.83.83 0 00.461-.343c.218-.369.376-.774.565-1.16a1 1 0 011.818-.011 4.877 4.877 0 01.376.809 1.029 1.029 0 001.081.8 4.664 4.664 0 01.835.122 1 1 0 01.568 1.6 2.611 2.611 0 01-.518.534 1.378 1.378 0 00-.528 1.723 3.246 3.246 0 01.115.685c.077.806-.749 1.459-1.4 1a2.238 2.238 0 00-2.887 0 .924.924 0 01-1.365-1.011 2.432 2.432 0 00-.881-2.888c-.514-.384-.384-1 .174-1.522m4 1.425l-.452-.378-.794.529.345 1h.84l.373-1.032c-.06-.021-.152-.054-.309-.114z"
            transform="translate(-492.92 306.749)"
            fill="#304457"
          />
          <path
            data-name="Path 60"
            d="M409.852 101.351a1.5 1.5 0 11-2.037.3 1.5 1.5 0 012.037-.3m-1.479 1.63a.668.668 0 00.912.254.917.917 0 00.418-.651.852.852 0 00-.406-.65.949.949 0 00-.771.1c-.308.187-.362.531-.153.947z"
            transform="translate(-255.962 387.717)"
            fill="#314658"
          />
          <path
            data-name="Path 62"
            d="M592.24 163.556a2.674 2.674 0 01.566.063l-.369.513-.342.482 1.081-.111v.507l-1.09-.095.675.879-.459.308-.483-1.022-.5 1.008-.426-.264.629-.889-1.022.065v-.491l1.038.084-.642-.881.447-.308.485 1.032z"
            transform="translate(-420.738 331.606)"
            fill="#2f4457"
          />
          <path
            data-name="Path 65"
            d="M854.2 112.658a1.022 1.022 0 01-1.342.105 1 1 0 111.342-.105m-.261-.564a2 2 0 00-.331-.573c-.072-.063-.364.017-.465.121a.6.6 0 00-.117.482c.097.425.532.441.911-.03z"
            transform="translate(-656.615 378.817)"
            fill="#36495b"
          />
          <path
            data-name="Path 66"
            d="M884.333 336.572c.216.624.125 1.032-.284 1.315a.951.951 0 01-1.16 0 .983.983 0 01-.365-1.147c.272-.784 1.181-.886 1.808-.168m-.395.383c-.13-.331-.386-.507-.68-.291a.838.838 0 00-.187.653c.008.1.363.276.458.226a1.225 1.225 0 00.41-.589z"
            transform="translate(-683.626 176.126)"
            fill="#36495b"
          />
          <path
            data-name="Path 67"
            d="M807.159 229.745l-.721.041v-.425l.886.083-.542-.769.373-.248.429.855.4-.831.413.183-.576.808.865-.075c.1.407.1.407-.822.388l.514.717-.385.255-.42-.867-.424.853-.384-.21c.165-.223.31-.419.485-.666-.01-.065-.05-.078-.091-.092z"
            transform="translate(-615.17 273.064)"
            fill="#2f4457"
          />
          <path
            data-name="Path 68"
            d="M283.354 242.951c-.646-.213-.919-.561-.876-1.072a1 1 0 012-.006c.055.6-.377 1.036-1.12 1.078m.188-.5c.394-.125.543-.391.331-.747a.553.553 0 00-.489-.2c-.16.052-.4.322-.364.423.059.197.282.351.517.522z"
            transform="translate(-143.392 261.781)"
            fill="#36495b"
          />
          <path
            data-name="Path 106"
            d="M560.077 689.645h-8.951v-13.424h10.909v13.424h-1.957m-.794-3.5c.619-.246.788-.477.792-1.1q.006-.87 0-1.74c0-.7-.381-1.124-.982-1.127-.62 0-1.006.434-1.01 1.153q-.005.845 0 1.691c.006.765.344 1.107 1.199 1.125z"
            transform="translate(-385.287 -130.129)"
            fill="#feffff"
          />
          <path
            data-name="Path 107"
            d="M647.2 446h4.25v10.411l-2.626-2.272c-.858-.743-1.706-1.472-2.548-2.221a.713.713 0 01-.26-.435c-.018-1.8-.012-3.609-.012-5.483z"
            transform="translate(-470.71 77.16)"
            fill="#fdfefe"
          />
          <path
            data-name="Path 111"
            d="M431.352 718.272c-.263.016-.433.011-.421-.3.021-.544.006-1.09.006-1.666h1.963v1.962z"
            transform="translate(-277.063 -166.226)"
            fill="#fbfcfd"
          />
          <path
            data-name="Path 113"
            d="M353.221 717.339v.966h-1.967v-1.924h1.967z"
            transform="translate(-205.322 -166.29)"
            fill="#fbfcfd"
          />
          <path
            data-name="Path 115"
            d="M393.228 677.443v.868h-1.967v-1.923h1.967v1.055z"
            transform="translate(-241.345 -130.281)"
            fill="#fbfcfd"
          />
          <g data-name="Group 20">
            <path
              data-name="Path 46"
              d="M340.934 662.023v3.086c0 .562-.262.819-.829.819h-8.266c-.592 0-.845-.251-.845-.839v-8.266c0-.585.256-.837.847-.837h8.266c.567 0 .827.258.827.821v5.215m-1.957 2.486h.511v-1.4h-1.374v1.4h.863m-3.724-.815v.8h1.4v-1.383h-1.4v.583m-1.887-.606h-.936v1.4h1.379v-1.4h-.444m5.058-1.419h1.071v-1.4h-1.4c0 .411.011.8 0 1.187-.008.219.113.223.334.211m-3.169-.126c.032.04.064.116.1.116.433.008.866.005 1.3.005v-1.388h-1.4v1.266m-1.421-.619v-.648h-1.4v1.372h1.4v-.723m0-2.979v-.511h-1.4v1.374h1.4v-.863m2.84.208v-.717h-1.4v1.371h1.4v-.654m2.83.619v-1.341h-1.384v1.388a13.693 13.693 0 001.376-.046z"
              transform="translate(145.915 543.345) translate(-330.991 -655.986)"
              fill="#2f4456"
            />
            <path
              data-name="Path 108"
              d="M432.115 757.64h-.828v-1.4h1.374v1.4z"
              transform="translate(145.915 543.345) translate(-424.165 -749.117)"
              fill="#fbfcfd"
            />
            <path
              data-name="Path 109"
              d="M390.994 756.865v-.549h1.4v1.384h-1.4z"
              transform="translate(145.915 543.345) translate(-386.733 -749.192)"
              fill="#fbfcfd"
            />
            <path
              data-name="Path 110"
              d="M352.213 755.995h.409v1.4h-1.379v-1.4z"
              transform="translate(145.915 543.345) translate(-349.805 -748.893)"
              fill="#fbfcfd"
            />
            <path
              data-name="Path 112"
              d="M390.992 717.628v-1.232h1.4v1.388c-.431 0-.864 0-1.3-.005-.035-.001-.067-.079-.1-.151z"
              transform="translate(145.915 543.345) translate(-386.73 -712.106)"
              fill="#fbfcfd"
            />
            <path
              data-name="Path 114"
              d="M352.643 676.91v.829h-1.4v-1.374h1.4z"
              transform="translate(145.915 543.345) translate(-349.801 -674.918)"
              fill="#fbfcfd"
            />
            <path
              data-name="Path 116"
              d="M432.724 677.677c-.461.024-.9.024-1.36.024v-1.389h1.384c0 .45 0 .896-.024 1.365z"
              transform="translate(145.915 543.345) translate(-424.237 -674.869)"
              fill="#fbfcfd"
            />
          </g>
          <path
            data-name="Path 117"
            d="M292.458 479.329q-1.023-1.12-1.967-2.161c.626-.091 1.4-.255 2.18-.3a1.777 1.777 0 001.737-1.212c.3-.745.709-1.445 1.113-2.249.462.918.9 1.748 1.3 2.593a1.246 1.246 0 001.052.783c.935.113 1.865.266 2.869.414a3.937 3.937 0 01-.259.312c-.584.565-1.159 1.14-1.762 1.683a1.258 1.258 0 00-.417 1.29c.178.9.322 1.816.5 2.814-.9-.462-1.736-.861-2.54-1.313a1.381 1.381 0 00-1.493.009c-.791.446-1.612.837-2.523 1.3.161-.922.3-1.756.453-2.587a1.356 1.356 0 00-.243-1.376z"
            transform="translate(-150.612 52.486)"
            fill="#fff"
          />
          <path
            data-name="Path 118"
            d="M747.293 335.686a1.654 1.654 0 00-.7 2.055 1.767 1.767 0 010 .436 2 2 0 00-2.8-.041 2.056 2.056 0 00-.871-2.686 2.03 2.03 0 002.284-1.666 1.994 1.994 0 002.342 1.595c-.135.165-.183.221-.255.307z"
            transform="translate(-557.979 178.2)"
            fill="#f9fafa"
          />
          <path
            data-name="Path 119"
            d="M216.455 336.913c.054.4.108.761.168 1.165a4.409 4.409 0 00-1.382-.506 4.933 4.933 0 00-1.471.5 1.974 1.974 0 00-.862-2.608 2.081 2.081 0 002.3-1.68 1.923 1.923 0 002.189 1.609c-.327.507-.634.992-.942 1.52z"
            transform="translate(-80.756 178.203)"
            fill="#f9fafa"
          />
          <path
            data-name="Path 120"
            d="M373.871 330.265c.087-.152.154-.277.26-.476a1.319 1.319 0 001.435.962 1.482 1.482 0 00-.568 1.826 1.265 1.265 0 00-1.727-.02 1.4 1.4 0 00-.467-1.674c.36-.204.703-.396 1.067-.618z"
            transform="translate(-224.726 181.798)"
            fill="#f6f8f8"
          />
          <path
            data-name="Path 121"
            d="M582.561 301.422c.063.064.124.132.109.17-.165.4-.341.8-.532 1.242a1.091 1.091 0 00-1.286.045l-.379-1.344 1.039-.782c.288.187.649.424 1.049.669z"
            transform="translate(-411.711 207.941)"
            fill="#f4f6f7"
          />
          <path
            data-name="Path 122"
            d="M485.267 552.815c.014.261.015.425-.295.421-1.209-.016-2.418-.006-3.658-.006v-1.966h3.953z"
            transform="translate(-322.428 -17.618)"
            fill="#fcfefe"
          />
          <path
            data-name="Path 123"
            d="M459.6 227.213a20.3 20.3 0 00-.024.376h-.912l-.339-1 .766-.516.8.544c-.084.165-.191.361-.291.596z"
            transform="translate(-301.726 275.179)"
            fill="#f4f6f7"
          />
          <path
            data-name="Path 124"
            d="M704.577 226.324l.279.1-.373 1.032h-.84l-.345-1 .794-.529c.132.116.293.25.485.397z"
            transform="translate(-522.303 275.309)"
            fill="#f4f6f7"
          />
          <path
            data-name="Path 125"
            d="M415.116 110.146a.647.647 0 01.171-.915.949.949 0 01.771-.1.852.852 0 01.406.65.917.917 0 01-.418.651.676.676 0 01-.93-.286z"
            transform="translate(-262.724 380.516)"
            fill="#f6f8f8"
          />
          <path
            data-name="Path 126"
            d="M858.845 116.866c-.368.432-.8.416-.9-.009a.6.6 0 01.117-.482c.1-.1.394-.185.465-.121a1.593 1.593 0 01.318.612z"
            transform="translate(-661.534 374.084)"
            fill="#f0f0f2"
          />
          <path
            data-name="Path 127"
            d="M889.389 341.343a1.4 1.4 0 01-.417.548c-.1.05-.45-.13-.458-.226a.838.838 0 01.187-.653c.299-.212.55-.04.688.331z"
            transform="translate(-689.069 171.777)"
            fill="#f0f0f2"
          />
          <path
            data-name="Path 128"
            d="M813.9 241.86a.087.087 0 01.084.04c-.032.008-.048 0-.084-.04z"
            transform="translate(-621.887 260.97)"
            fill="#fff"
          />
          <path
            data-name="Path 129"
            d="M288.412 247.247c-.2-.173-.42-.326-.484-.53-.032-.1.2-.372.364-.423a.553.553 0 01.489.2c.212.36.064.626-.369.753z"
            transform="translate(-148.302 256.985)"
            fill="#f0f0f2"
          />
          <path
            data-name="Path 139"
            d="M622.132 739.978c-.815-.022-1.153-.363-1.158-1.132q-.005-.845 0-1.691c0-.719.39-1.156 1.01-1.153.6 0 .977.432.982 1.127q.006.87 0 1.74c-.006.631-.175.859-.834 1.109z"
            transform="translate(-448.176 -183.957)"
            fill="#2f4457"
          />
          <path
            data-name="Rectangle 18"
            transform="translate(125.656 559.224)"
            fill="#fff"
            d="M0 0H71V7H0z"
          />
        </g>
        <g data-name="Group 19" transform="translate(15 28)">
          <rect
            data-name="Rectangle 15"
            width={152}
            height={20}
            rx={2}
            transform="translate(90 629)"
            fill="#4ba0e8"
          />
          <text
            data-name="Property Care"
            transform="translate(111 643)"
            fill="#fff"
            fontSize={12}
            fontFamily="Poppins-Bold, Poppins"
            fontWeight={700}
            letterSpacing="0.1em"
          >
            <tspan x={0} y={0}>
              {"PROPERTY CARE"}
            </tspan>
          </text>
        </g>
        <text
          data-name="G S"
          transform="translate(159 654)"
          fill="#4ba0e8"
          fontSize={26}
          fontFamily="Poppins-Bold, Poppins"
          fontWeight={700}
        >
          <tspan x={0} y={0}>
            {"G S"}
          </tspan>
        </text>
        <path
          data-name="Line 4"
          transform="translate(105.5 628.5)"
          fill="none"
          stroke="#4ba0e8"
          strokeWidth={5}
          d="M44 0L0 0"
        />
        <path
          data-name="Line 5"
          transform="translate(204.5 628.5)"
          fill="none"
          stroke="#4ba0e8"
          strokeWidth={5}
          d="M53 0L0 0"
        />
      </g>
    </svg>
  );
};

export default Logo;
