import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import "animate.css";
import Home from "./Home/Home";
import Footer from "./Footer/Footer";
import "./otherPage.css";
import "./mobile-main.css";
import { useState } from "react";
import Header from "./Header/header";
// import Services from "./Services/Services";
import Products from "./Services/products";
import ProductDetail from "./Services/ProductDetail";
import QueryForm from "./Header/QueryForm";
import AboutUs from "./Home/AboutUs/AboutUs";
import AboutUsPage from "./AboutUsPage/AboutUsPage";
import FormQuery from "./FormQuery/FormQuery";

function App() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [formOpen, setFormOpen] = useState(false);

  const handleForm = () => {
    setFormOpen(!formOpen);
    console.log(formOpen);
  };

  return (
    <>
      <BrowserRouter>
        <Header
          style={{ marginBottom: "50px" }}
          scrollToSection={scrollToSection}
          handleForm={handleForm}
        />
        <Routes>
          <Route path="/Services" element={<Products />} />
          <Route path="/:productName" element={<ProductDetail />} />
          <Route
            path="/about-us"
            element={
              <div className="about-us-page">
                <AboutUsPage />
              </div>
            }
          />
          {/* <Route
            path="/"
            element={
              <Home
                formOpen={formOpen}
                handleForm={handleForm}
                scrollToSection={scrollToSection}
              />
            }
          /> */}
          <Route path="/" element={<FormQuery />} />
        </Routes>
        <Footer scrollToSection={scrollToSection} />
      </BrowserRouter>

      <div
        className={`form-container-main-close ${
          formOpen ? "form-container-main-open" : ""
        }`}
      >
        <QueryForm handleForm={handleForm} />
      </div>
    </>
  );
}

export default App;
